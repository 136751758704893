<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" md="8">
          <h4>Yapılan Mal Kabulleri</h4>
        </b-col>
        <b-col cols="12" md="4" sm="12" class="text-left"> </b-col>
        <b-col cols="6" md="2" sm="12" class="text-right"> </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12" md="2">
          <b-form-group>
            <label class="d-inline-block text-sm-left mr-50">Satır</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              label-for="name-input"
            />
          </b-form-group> </b-col
        ><b-col cols="12" md="6"></b-col>
        <b-col cols="12" md="4">
          <b-form-group
            label-align-sm="left"
            label-for="name-input"
            class="mb-0"
            label="Ara"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                placeholder="Ara"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <b-table
            :striped="false"
            :bordered="false"
            :hover="true"
            :busy="show"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :sticky-header="stickyHeader"
            responsive
            :filter="filter"
            class="custom-table"
          >
            <template #cell(unitPrice)="data">
              {{ formatCurrency(data.item.unitPrice) }}
            </template>

            <template #cell(actions)="data">
              <b-row>
                <b-col cols="12" md="6">
                  <b-button
                    @click="editQuantity(data.item)"
                    size="sm"
                    variant="warning"
                    pill
                    block
                  >
                    <feather-icon icon="EditIcon" /> Düzenle
                  </b-button>
                </b-col>
                <b-col cols="12" md="6">
                  <b-button
                    @click="deleteProduct(data.item)"
                    pill
                    size="sm"
                    variant="danger"
                    block
                  >
                    <feather-icon icon="TrashIcon" /> Sil
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

      <b-modal
        id="modal-prevent-update"
        ref="my-modal"
        title="Mal Kabul Güncelle"
        ok-title="Güncelle"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok="updateMethod"
        size="lg"
        size-sm
      >
        <form ref="form" @submit.stop.prevent="add">
          <b-form-group label="Miktar" label-for="name-input">
            <b-form-input
              size="sm"
              type="number"
              id="name-input"
              v-model="selectedProduct.quantity"
            />
          </b-form-group>
          <b-form-group label="Alış Fiyatı" label-for="name-input">
            <b-form-input
              size="sm"
              id="name-input"
              type="number"
              v-model="selectedProduct.unitPrice"
            />
          </b-form-group>
        </form>
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,
      products: [],
      allRows: [],

      selectedProduct: "",
      items: [],
      fields: [
        { key: "product.barcode", label: "Barkod", sortable: true },
        { key: "product.name", label: "Ürün Adı", sortable: true },
        {
          key: "quantity",
          label: "Miktar",
          sortable: true,
          tdClass: "custom-width-quantity",
        },
        {
          key: "unitPrice",
          label: "Alış Birim Fiyatı (TL)",
          sortable: true,
          tdClass: "custom-width-unitPrice",
        },
        { key: "actions", label: "Aksiyon" },
      ],

      boxOne: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.getBuys();
  },
  methods: {
    formatCurrency(amount) {
      const options = {
        style: "currency",
        currency: "TRY",
        currencyDisplay: "symbol", // Use the currency symbol
      };
      const formatter = new Intl.NumberFormat("tr-TR", options);
      return formatter.format(amount);
    },
    async updateMethod() {
      if (
        this.selectedProduct.quantity == null ||
        this.selectedProduct.quantity == ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Miktar Boş Olamaz..",
          },
        });
        return;
      }
      if (this.selectedProduct.unitPrice == null) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Birim Fiyat Boş Olamaz..",
          },
        });
        return;
      }
      this.selectedProduct.product = null;
      this.$http
        .put("buys", this.selectedProduct)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.$refs["my-modal"].hide();

            this.getBuys();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async editQuantity(data) {
      this.selectedProduct = data;
      this.$refs["my-modal"].show();
    },
    async deleteProduct(data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(
          "Mal Kabul Evrakını Silmek İstediğinize Emin misiniz ?",
          {
            title: "Dikkat",
            size: "sm",
            okVariant: "primary",
            okTitle: "Evet",
            cancelTitle: "Hayır",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: false,
          }
        )
        .then((value) => {
          this.boxOne = value;
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`buys?id=${data.id}`)
              .then((response) => {
                if (response.status === 200) {
                  this.items = this.items.filter((i) => i.id !== data.id);
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async getBuys() {
      this.show = true;
      this.$http
        .get("buys")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.items = satirlar;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style>
.custom-width-actions {
  width: 180px; /* Set the width of the Actions column */
}
.custom-width-quantity {
  min-width: 180px; /* Set the width of the Actions column */
}
.custom-width-unitPrice {
  min-width: 180px; /* Set the width of the Actions column */
} /* Example media query for smaller screens */
</style>
